<template>
    <form @submit.prevent="handleSubmit">
        <input type="email" required placeholder="email" v-model="email">
        <input type="password" required placeholder="password" v-model="password">
        <br>
        <div v-if="err" class="error">Login Error: {{ err }} Contact Steve if you need to reset your password.</div>
        <button type="submit" :disabled="loading">
          {{ loading ? 'Logging in...' : 'Login' }}
        </button>
        <div v-if="emailUnverified">    
          <p>You have successfully logged in, however, you still need to verify your email address to access the directory.</p>
          <p>Please click the "Logout" link above, check your email account for the verification link (perhaps in your spam folder),</p>
          <p>and once you've followed the verification link come back to log in. If you still have issues, contact Steve.</p>
        </div>
    </form>
</template>

<script setup>
/* This component will log a valid user in and will check to see if their email account has been verified before
* allowing access to the directory. */

  import { ref } from 'vue';
  import { signInWithEmailAndPassword } from 'firebase/auth';
  import { auth } from '../firebase/config.js'; // Import the pre-initialized auth instance

  const emit = defineEmits(['successfulLogin']); // Declare the events to be emitted
  const email = ref('');
  const password = ref('');
  const loading = ref(false);
  const err = ref(null); //THIS WAS ORIGINALLY "ERROR" AND IT APPEARS IT SHOULD BE SOME OTHER NAME TO NOT CONFLICT
                      // WITH THE CATCH BLOCK ERROR!! TOOK A LOT OF TRIAL & ERROR (NO PUN INTENDED) TO SORT THIS OUT!!!
  const emailUnverified = ref(false); //prior to log in assume email has been verified

  const handleSubmit = async () => {
    err.value = null; //reset err in cases where there were previous errors
    loading.value = true;

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
       if (userCredential.user.emailVerified) { //with successful log in, check if email has been verified
        emit('successfulLogin'); // Emit a success event up to the parent component
      } else { //successful login, but email not yet verified
        loading.value = false;
        emailUnverified.value = true; 
      }      
    } catch (error) { //unsuccessful login, handle accordingly
      loading.value = false;
      if (error.code === 'auth/invalid-credential') {
        err.value = 'Invalid email or password.'; // setting the message for the display
      } else {
        err.value = 'An authentication error occurred. Please try again.'; // setting the message for the display
      }
    } 
  }
</script>

<style>

</style>