<template>
    <div>        
        <div id="field-note">
            <button class="pointer2" @click="getHouseholds">{{ displayHouseholdsTable ? 'Show Inidividuals' : 'Show Households' }}</button>
            <p>Scroll down to beneath the table of contacts to access other fields available for display.</p>
        </div>

        <br>
        <!--Display the individual table if the user has selected to do so in place of the household table.-->
        <table border:1 v-if="!displayHouseholdsTable">
            <!--Display column headers and provide ability to hide certain columns-->
            <thead>
                <th>Last Name</th>
                <th>First Name</th>
                <th class="pointer" v-if="showStatus" @click="showStatus = ! showStatus">Membership Status</th>
                <th class="pointer" v-if="showGender" @click="showGender = ! showGender">Gender</th>
                <th class="pointer" v-if="showHousehold" @click="showHousehold = ! showHousehold">Household</th>
                <th class="pointer" v-if="showPAddress" @click="showPAddress = ! showPAddress">Physical Address</th>
                <th class="pointer" v-if="showMAddress" @click="showMAddress = ! showMAddress">Mailing Address</th>
                <th class="pointer" v-if="showEmail" @click="showEmail = ! showEmail">E-mail Address</th>
                <th class="pointer" v-if="showCPh" @click="showCPh = ! showCPh">Cell Phone</th>
                <th class="pointer" v-if="showHPh" @click="showHPh = ! showHPh">Home Phone</th>
            </thead>

            <!--Provide rows of contacts that attend the church and provide ability to open an individual or household contact card-->
            <tbody>
                <tr v-for="contact in sheep" :key="contact.id">
                    <td class="pointer" @click="toggleIndCard(contact)">{{ contact.lastName }}</td>
                    <td class="pointer" @click="toggleIndCard(contact)">{{ contact.firstName }}</td>
                    <td v-if="showStatus">{{ contact.mStatus }}</td>
                    <td v-if="showGender">{{ contact.gender }}</td>
                    <td v-if="showHousehold" class="pointer" @click="toggleHouseCard(contact.household)">{{ contact.household }}</td>
                    <td v-if="showPAddress">{{ contact.pAddrStreet == "" ? "" : contact.pAddrStreet + ", " + contact.pAddrCity + ", " + contact.pAddrState + " " + contact.pAddrZip }}</td>
                    <td v-if="showMAddress">{{ contact.mAddrStreet == "" ? "" : contact.mAddrStreet + ", " + contact.mAddrCity + ", " + contact.mAddrState + " " + contact.mAddrZip }}</td>
                    <td v-if="showEmail">{{ contact.email }}</td>
                    <td v-if="showCPh">{{ contact.cPh }}</td>
                    <td v-if="showHPh">{{ contact.hPh }}</td>
                </tr>
            </tbody>
        </table>
        <!--Display the household table if the user has selected to do so in place of the individual table.-->
        <table border:1 v-if="displayHouseholdsTable">
            <!--Display column headers and provide ability to hide certain columns-->
            <thead>
                <th>Household</th>
                <th class="pointer" v-if="showPAddress" @click="showPAddress = ! showPAddress">Physical Address</th>
                <th class="pointer" v-if="showMAddress" @click="showMAddress = ! showMAddress">Mailing Address</th>
                <th class="pointer" v-if="showHPh" @click="showHPh = ! showHPh">Home Phone</th>
            </thead>

            <!--Provide rows of contacts that attend the church and provide ability to open an individual or household contact card-->
            <tbody>
                <tr v-for="houseName in uniqueHouseholds" :key="houseName.household">
                    <td v-if="showHousehold" class="pointer" @click="toggleHouseCard(houseName.household)">{{ houseName.household }}</td>
                    <td v-if="showPAddress">{{ houseName.pAddrStreet == "" ? "" : houseName.pAddrStreet + ", " + houseName.pAddrCity + ", " + houseName.pAddrState + " " + houseName.pAddrZip }}</td>
                    <td v-if="showMAddress">{{ houseName.mAddrStreet == "" ? "" : houseName.mAddrStreet + ", " + houseName.mAddrCity + ", " + houseName.mAddrState + " " + houseName.mAddrZip }}</td>
                    <td v-if="showHPh">{{ houseName.hPh }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--Getting the contact info passed to the IndividualCard component took a while to figure out (~2 hrs). When the component was
        placed in the table above, it would pass the last contact in the list regardless of the contact clicked on. When placed 
        outside the table, there was no longer access to the specific contact since v-for is in the <tr> element. The eventual solution 
        was to pass the selected contact out of the table and into the toggleIndCard function to update a variable to pass into
        the component. A similar approach was needed for the HouseholdCard. I think Vue documentation addresses table limitations. -->

    <!--Display an individual contact card if an indivudal's name has been clicked; pass into the component the contact that was clicked-->
    <IndividualCard v-if="showIndCard" @click="toggleIndCard" :contact='contactSel' @household-clicked="toggleHouseCard"/>

    <!--Display a household contact card if a household name has been clicked; pass in the house name and all contacts in the household.-->
    <div v-if="showHouseCard" @click="toggleHouseCard">
        <HouseholdCard :house="houseSel" :houseNames="houseNames" @name-in-household-clicked="toggleIndCard"/>
    </div>
     
    <!--Columns that are hidden are listed here. Clicking on a column name adds it back to the table.-->
    <!--footer for individual table-->
    <footer v-if="!displayHouseholdsTable">
        <p>Any field except the last and first name can be hidden. To hide a field, click on the associated column header. To unhide a field, click on the field in the list below.</p>
        <ul>
            <li class="pointer" v-if="!showCPh" @click="showCPh = !showCPh">Cell Phone</li>
            <li class="pointer" v-if="!showEmail" @click="showEmail = !showEmail">E-mail Address</li>            
            <li class="pointer" v-if="!showGender" @click="showGender = !showGender">Gender</li>
            <li class="pointer" v-if="!showHPh" @click="showHPh = !showHPh">Home Phone</li>
            <li class="pointer" v-if="!showHousehold" @click="showHousehold = !showHousehold">Household</li>
            <li class="pointer" v-if="!showMAddress" @click="showMAddress = !showMAddress">Mailing Address</li>
            <li class="pointer" v-if="!showStatus" @click="showStatus = !showStatus">Membership Status</li>            
            <li class="pointer" v-if="!showPAddress" @click="showPAddress = !showPAddress">Physical Address</li>             
        </ul> 
    </footer> 
     <!--footer for household table-->
    <footer v-if="displayHouseholdsTable">
        <p>Any field except the last and first name can be hidden. To hide a field, click on the associated column header. To unhide a field, click on the field in the list below.</p>
        <ul>
            <li class="pointer" v-if="!showHPh" @click="showHPh = !showHPh">Home Phone</li>
            <li class="pointer" v-if="!showMAddress" @click="showMAddress = !showMAddress">Mailing Address</li>
            <li class="pointer" v-if="!showPAddress" @click="showPAddress = !showPAddress">Physical Address</li>
        </ul> 
    </footer>
</template>

<script>
/* This component displays a table of contacts. Each row in the table is a contact with various fields holding info about the contact.
* The component will respond to a name being clicked on by popping up an overlaid modal contact card. The contact card has a link to
* the contact's associated household card. The modal household card lists those in the household. If an individual listed on the household
* card is clicked on the individual's contact card comes up. Additionally, in the table a household can be clicked on to bring up the
* household card. In the table, certain fields/column headers can be clicked on to hide them. A list of hidden fields is provided after the
* last row in the table and can be clicked on to make that column reappear in the table.
*/

import IndividualCard from './IndividualCard.vue'
import HouseholdCard from './HouseholdCard.vue'
import { database } from '../firebase/config'
import { ref, onValue } from "firebase/database"

export default {
    components: { IndividualCard, HouseholdCard },
    data() {
        return {
            sheep: [], //an array to hold church members and regular attendees
            uniqueHouseholds: [], //array of households
            displayHouseholdsTable: false,

            //flags to determine if a given field/column is to be displayed in the table
            showGender: false,
            showStatus: true,
            showHousehold: true,
            showPAddress: false,
            showMAddress: false,
            showEmail: true,
            showCPh: true,
            showHPh: true,
            //end list of field display flags

            //flags to determine if respective card modal is displayed
            showIndCard: false,
            showHouseCard: false,
            //end list of modal flags            

            contactSel: {}, //holds a contact when a name is clicked on to pass to the individual card
            houseSel: '', //holds a household name when a household is clicked on to pass to the household card
            houseNames: [] //an array of those in a given household to pass to the household card
        }
    },
    methods: {
        toggleIndCard(contactSelected) {
            this.showIndCard = !this.showIndCard;
            this.contactSel = contactSelected;
        },
        toggleHouseCard(houseHold) {
            this.showHouseCard = !this.showHouseCard;
            this.houseSel = houseHold;
            //Capture all of the contacts in the house to pass to the HouseholdCard component.
            this.houseNames = this.sheep.filter((person) => person.household == houseHold );
        },
        getHouseholds() {                    
            //extract unique households from the array of objects representing members and attendees            
            if (this.uniqueHouseholds.length == 0) {
                const uniqueHouseholdsMap = new Map(); //create a map to store unique households
                this.sheep.forEach(obj => {
                    const key = obj.household;
                    //check if the household is not already in the map
                    if (!uniqueHouseholdsMap.has(key)) {                        
                        //add the unique household name
                        uniqueHouseholdsMap.set(key, obj.pAddrStreet, obj.pAddrCity, obj.pAddrState, obj.pAddrZip, obj.mAddrStreet, obj.mAddrCity, obj.mAddrState, obj.mAddrZip)
                        this.uniqueHouseholds.push({ 
                            household: key, 
                            pAddrStreet: obj.pAddrStreet,
                            pAddrCity: obj.pAddrCity, 
                            pAddrState: obj.pAddrState, 
                            pAddrZip: obj.pAddrZip, 
                            mAddrStreet: obj.mAddrStreet, 
                            mAddrCity: obj.mAddrCity, 
                            mAddrState: obj.mAddrState, 
                            mAddrZip: obj.mAddrZip,
                            hPh: obj.hPh
                        });               
                    }
                });               
            }
            this.displayHouseholdsTable = !this.displayHouseholdsTable; //toggle boolean value to determine whether to display the individual or household table
            //for household table set all the possible fields to true
            this.showHousehold = true; 
            this.showPAddress = true;
            this.showMAddress = true;
            this.showHPh = true;
        }
    },
    created() {  
        //When component is created, connect to the database (firebase.google.com - see firebase/config.js file) 
        const sheepRef = ref(database, "sheep"); //get a pointer to the database node to use
        
        onValue(sheepRef, (snapshot) => {
            this.sheep = snapshot.val(); //fill the array with objects that each represent a member or attendee
            //and all of their contact data, etc.

            // //sort the array by last name then by first name
            // this.sheep.sort(function(a, b) {
            //     // Compare last names
            //     var lastNameComparison = a.lastName.localeCompare(b.lastName);
            //     // If last names are equal, compare first names
            //     if (lastNameComparison === 0) {
            //         return a.firstName.localeCompare(b.firstName);
            //     }
            //     return lastNameComparison;
            // });
            // sort the array by last name, then household, and then first name
            this.sheep.sort(function(a, b) {
            // Compare last names
            var lastNameComparison = a.lastName.localeCompare(b.lastName);

            // If last names are equal, compare households
            if (lastNameComparison === 0) {
                // If households are also equal, compare first names
                if (a.household === b.household) {
                return a.firstName.localeCompare(b.firstName);
                } else {
                return a.household.localeCompare(b.household);
                }
            }
            // Return the base comparison for last names
            return lastNameComparison;
            }); 
        });
    }
}
</script>

<style scoped>

#field-note {
    text-align: left;
}

footer {
    text-align: left;
}

.pointer {
    cursor: pointer;
}

.pointer:hover {
    color: red;
}

.pointer2 {
    cursor: pointer;
}

.pointer2:hover {
    color: black;
}

table {
    margin-left: auto;
    margin-right: auto;        
    width: 100%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    background-color: white;
}

th {
    background-color: lightblue;
    position: sticky;
    top: 0;
    text-align: center;
}

/* Add some styling for better visualization */
body {
    font-family: Arial, sans-serif;
    margin: 20px;
}
</style>