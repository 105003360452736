<template>
    <div class="backdrop">
        <!--Display the household name at the top of the card-->
        <div class="modal">
            <p id="hh-modal-title">{{ house }} Household</p>
            <p>
                <!--List on the card each name within the household and the relationship; the names are in a sorted order (see created lifecycle hook)-->
                <!--Take action when a name on the card is clicked on (see nameClicked method)-->
                <ul class="pointer" id="hh-modal-names" v-for="name in houseNames" @click="nameClicked(name)">
                    {{ name.firstName }} {{ name.lastName }} ({{ name.hhRel }})
                </ul> 
            </p>
        </div>       
    </div>
</template>

<script>
/* This component provides the display of a household contact card, which contains a list of individuals in the home, 
* each of which can be clicked on. */

export default {
    //Household name and an array of those in the household are passed in from the parent component.
    props: [ 'house', 'houseNames' ],
    methods: {
        //Emit an event to the listening parent component when a name on the card is clicked and provide the name object
        nameClicked(nameClickedOn) {
            this.$emit('name-in-household-clicked', nameClickedOn)
        }
    },
    created() { 
        //Sort the names by childNum to, e.g., get head of household at top of the list, then wife, then children from oldest
        //to youngest; childNum in the database for head of household is -1 and for a wife it is 0.
        this.houseNames.sort((a, b) => a.childNum - b.childNum);
    }
}
</script>

<style>
#hh-modal-names {
    text-align: left;
    font-style: normal;
    font-weight: lighter;
}

#hh-modal-title {
    text-align: center;
    font-style: italic;
    font-weight: bold;
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

.pointer:hover {
    color: blue;
}
</style>