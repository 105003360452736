<template>
    <div class="backdrop">
        <!--Display pertinent contact info on an individual's card-->
        <p class="modal">
            {{ contact.firstName }} {{ contact.lastName }}<br>
            <div v-if="contact.pAddrStreet !== ''">
                {{ contact.pAddrStreet }}<br>
                {{ contact.pAddrCity }}, {{ contact.pAddrState }} {{ contact.pAddrZip }}<br>
            </div>
            <div v-else><br>No physical address on file.</div>
            <br>
            {{ contact.email == '' ? "No email address on file." : contact.email }}<br><br>
            {{ contact.cPh == '' ? "" : "Cell: " + contact.cPh }}<br>
            {{ contact.hPh == '' ? "" : "Home: " + contact.hPh }}<br>
            <div v-if="contact.mAddrStreet !== ''">
                <br>
                {{ contact.mAddrStreet !== '' ? "Mailing Address: " : "" }}<br>
                {{ contact.mAddrStreet !== '' ? contact.mAddrStreet : ""}}<br>
                {{ contact.mAddrStreet !== '' ? contact.mAddrCity + ", " + contact.mAddrState + " " + contact.mAddrZip : ""}}
            </div>
            <!--Take action when household name on the card is clicked on (see householdClicked method)-->
            <div class="pointer" id="ind-house-title" @click="householdClicked(contact.household)">
                <br>{{ contact.household }} Household
            </div>
        </p>

    </div>
</template>

<script>
/* This component provides the display of an individual contact card which has the contact's info and a link to the
* household they belong to. */

export default {
    props: ['contact'], // The contact to be displayed is sent in from the parent component.
    methods: {
        householdClicked(householdClickedOn) {
            this.$emit('household-clicked', householdClickedOn) //Let parent component know that the household name on
            //the individual's card has been clicked on.
        }
    }
}
</script>

<style>
.modal {
        width: 300px;
        padding: 20px;
        margin: 100px auto;
        background: lightblue;
        border-radius: 10px;
        text-align: left;
    }
.backdrop {
        top: 0;
        position: fixed;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
    }
.pointer {
    cursor: pointer;
}

.pointer:hover {
    color: blue;
}

#ind-house-title {
    text-align: center;
    font-style: italic;
    text-decoration: underline;
    font-weight: bold;
}
</style>