<template>
  <body>
    <nav>
      <div class="tpc_banner">
        <a href="https://www.thebiblealone.com/" target="_blank">
          <img src="./assets/tpc_banner.png" alt="Trinity Presbyterian Church" width="400px" height="120px"> 
        </a>
        
      </div>

      <router-link :to="{ name: 'welcome' }">Login</router-link> |
      <router-link :to="{ name: 'dir' }">Directory</router-link> | 
      <router-link :to="{ name: 'help' }">Help</router-link> |
      <!-- <router-link :to="{ name: 'about' }">About</router-link> | -->
      <a href="/"><span @click="logout">Logout</span></a>      
    </nav>
    <router-view/>
  </body>
</template>

<script>
  import { signOut } from 'firebase/auth';
  import { auth } from './firebase/config.js'; // Import the pre-initialized auth instance
  export default {
    methods: {
      async logout() {
        await signOut(auth);
      }
    }
  }

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background-image: url('./assets/tpc_sqsp_background.jpg');
  background-repeat: no-repeat;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.tpc_banner {
  text-align: center;
}
</style>
