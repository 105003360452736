<template>
  <div v-if="this.user && this.user.emailVerified">  <!--Originally was trying to check login and email verification in a
  created() lifecycle hook. Trying to check emailVerified led to bizarre behavior; hanging the browser as the email 
  verification check kept repeating itself. Quite a lot of time was spent troubleshooting this. Don't know why it took so 
  long to arrive at this very simple solution!!!-->  
    <TableView/>
  </div>
  
  <div v-else-if="this.user && !this.user.emailVerified">
    <p>You have successfully logged in, however, you still need to verify your email address to access the directory.</p>
    <p>Please click the "Logout" link above, check your email account for the verification link (perhaps in your spam folder),</p>
    <p>and once you've followed the verification link come back to log in. If you still have issues, contact Steve.</p>  
  </div>
  <div v-else>To access the directory, please log in and ensure you have verified your email address.</div>
</template>

<script>
import TableView from '@/components/TableView.vue'
import { auth } from '@/firebase/config';

export default {
  name: 'HomeView',
  components: { TableView },
  data() {
    return {
      user: auth.currentUser //might be able to use auth.currentUser right in the template and do away with this, but leaving it
      //as is. Why mess with it since it is working and it is a bit more readable in its current state.
    }
  }  
}
</script>
