<template>
    <div class="welcome container">  
      <h1>Welcome</h1>
      <!-- <p>Check back once it has been announced at TPC that the Online Church Directory is available. Thanks!</p> -->
        
        
        <div v-if="showLogin">
          <h2>Login</h2> 
          <LoginForm @successfulLogin="$router.push('/dir')"/>
          <p>Not registered yet? <span @click="showLogin = false">Register</span> instead.</p>
        </div>
        <div v-if="!showLogin">
          <h2>Register</h2>
          <SignupForm />
          <p>Already registered? <span @click="showLogin = true">Login</span> instead.</p>
        </div>


    </div>


</template>

<script>
  import SignupForm from '../components/SignupForm.vue';
  import LoginForm from '../components/LoginForm.vue';
  import { auth } from '../firebase/config.js'; // Import the pre-initialized auth instance

  export default {
    components: { SignupForm, LoginForm },
    data() {
      return {
        showLogin: true,
        user: auth.currentUser
      }
    },
    created() {
      if (this.user) {
        this.$router.push('/dir');
      }
    }
  }

</script>

<style>
  .container {
    width: 90%;
    max-width: 960px;
    margin: 80px auto;
    border-radius: 20px;
    box-shadow: 2px 4px 6px rgba(28, 6, 49, 0.1);
  }

  .welcome {
    text-align: center;
    padding: 20px 0;
  }

  .welcome form {
    widows: 300px;
    margin: 20ps auto;
  }

  .welcome label {
    display: block;
    margin: 20px 0 10px;
  }

  .welcome input {
    width: 50%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #eee;
    outline: none;
    color: #999;
    margin: 10px auto;
  }

  .error {
    color: #ff3f80;
    font-size: 14px;
  }

  button {
    text-decoration: none;
    background: #61c99a;
    color: white;
    font-weight: bold;
    border: 0;
    border-radius: 20px;
    padding: 10px 20px;
  }

  .welcome span {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .welcome button {
    margin: 20px auto;
  }
</style>
  