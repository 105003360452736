<template>
  <div class="help">
    <div id="field-note">
      <h3 id="underline">Available Directory Actions</h3>
      <ul>
          <li><span id="underline">Contact Card View</span>: In the directory table, click on a last, first, or household name for a contact card view. When a contact card is displayed, click anywhere outside the card to return to the tabular view.</li>
          <br>
          <ul>
            <li><span id="underline">Household Contact Card View</span>: When a household contact card is displayed, click on an individual to switch to their card.</li>
            <br>
            <li><span id="underline">Individual Contact Card View</span>: When an individual contact card is displayed, click on the household to switch to the household card.</li>
            <br>
          </ul>
          <li><span id="underline">Directory Table</span>: The default view for the directory is a list of names sorted by the last name, then by the household name, and finally by the first name.</li>
          <br>
          <li><span id="underline">Directory Table Fields</span>: Any field (i.e., a column) in the table can be hidden, except the last and first names.</li>
          <br>
          <ul>
              <li>To hide a field, click on the associated column header.</li>
              <br>
              <li>To unhide a field, click on the field in the list below below the table.</li>
          </ul>
          <br>
          <li><span id="underline">Show Households/Individuals Button</span>: Toggles between a table of individuals or table of households.</li>
      </ul>
      <br>
      <h3 id="underline">Future Features</h3>
      <ul>
        <li>There are additional features planned that will be listed here soon!</li>
      </ul>
    </div>
  </div>
</template>

<style scoped>

#field-note {
    text-align: left;
}

#underline {
  text-decoration: underline;
  font-style: italic;
}

</style>
