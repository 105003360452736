//commented out code has been replaced with code from Firebase documentation

// import firebase from 'firebase/app';
// import 'firebase/database';
// import 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCv7tyF7j-BJRdc2RoTNFXCMMh9JFXB5M4",
  authDomain: "church-dir-realtime-db.firebaseapp.com",
  databaseURL: "https://church-dir-realtime-db-default-rtdb.firebaseio.com",
  projectId: "church-dir-realtime-db",
  storageBucket: "church-dir-realtime-db.appspot.com",
  messagingSenderId: "213006965535",
  appId: "1:213006965535:web:1e42406dc9095be9be2e2a"
};

// Initialize Firebase
//firebase.initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);

//export default firebase;
const database = getDatabase(app);
const auth = getAuth(app);

export { database, auth };